<template>
  <el-dialog v-model="dialogVisible" title="编辑头像" :close-on-click-modal="false" width="420px">
    <el-upload ref="uploadRef" class="upload-demo" drag action="#" :limit="1" :accept='faceimg_accept'
      :http-request="ImgHttpImport" list-type="picture-card" multiple>
      <div class="dragimgbox" v-show="!state.nowFile">
        <div class="dragbox w-236 h-236 dp-fc ac-c fw-w m-0-at">
          <div>
            <img src="@/assets/img/general/add.png">
            <div>上传照片</div>
          </div>
        </div>
        <div class="font">只支持.jpg 格式</div>
      </div>
      <template #file="{ file }">
        <div class="dragbox w100 dp-fc imgdragbox" v-show="state.nowFile">
          <img class="fileImg" :src="file.url" alt="" />
          <div class="delbox dp-fc" @click="resetFile()">
            <img class="w-28 h-28" src="@/assets/img/general/delete.png">
          </div>
        </div>
      </template>
    </el-upload>
    <template #footer>
      <span class="dialog-footer dp-f jc-c">
        <oabutton width='120' height='48' style="border-radius: 4px;font-size: 18px;"
          title="取消" CSStype=1 @buttonclick="dialogVisible=false" />
        <oabutton v-model:loading="dialogloading" class="ml-10" width='120' height='48'
          style="border-radius: 4px;font-size: 18px;" title="确认" CSStype=2 @buttonclick="saveData" />
      </span>
    </template>
  </el-dialog>
  
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { handleMessage } from "@/utils/server/confirm";
import { faceimg_accept } from "@/utils/base/accept"
import { getfileTypeName } from "@/utils/server/file.js"
import { uploadFile } from "@/utils/server/upload.js"
import {resetUserInfo} from "@/api/user/index"
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const state = reactive({
  nowFile: false,
});
const uploadRef = ref()//上传组件ref
// 图片上传方法
const ImgHttpImport = ((rawFile) => {
  console.log('rawFile', rawFile, rawFile.file.size)
  let fileTypeName = getfileTypeName(rawFile.file.name)
  let imgtype = ['jpg', 'JPG']
  console.log('fileTypeName', fileTypeName)
  if (rawFile.size / 1024 / 1024 > 1) {
    handleMessage('图片大小超过1MB')
    resetFile()
  } else if (imgtype.includes(fileTypeName)) {
    state.nowFile = rawFile
  } else {
    handleMessage('请上传正确图片格式!')
    resetFile()
  }
})
const resetFile = (() => {
  state.nowFile = false
  nextTick(() => {
    unref(uploadRef).clearFiles()
  })
})
const saveData=(()=>{
  if(state.nowFile){
    dialogloading.value = true
    uploadFile(state.nowFile.file, "Edit_avatar").then((res) => {
      httpToken({
        method: "post",
        url: '/admin/sys-user/edit',
        data: {
          avatar:res.data.fileUrl
        }
      }).then((res) => {
        resetUserInfo(()=>{
          // 刷新页面当前
          location.reload();
        },()=>{
          dialogloading.value = false
        })
      }).catch(err => {
        dialogloading.value = false
      })
    }).catch(err => {
      dialogloading.value = false
    })
  }else{
    handleMessage('请上传头像')
  }
})
const show = (() => {
  dialogloading.value = false
  dialogVisible.value = true
  resetFile()
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element/settingFrom.scss"; //element 样式重置
@import '@/styles/color/value.scss';

::v-deep .el-upload-dragger {
  border: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

::v-deep .el-upload--picture-card {
  border: none !important;
}

::v-deep .el-upload--picture-card {
  --el-upload-picture-card-size: 100%;
}

::v-deep .el-upload-list--picture-card {
  --el-upload-list-picture-card-size: 100%;
  display: block;
}

.dragimgbox {
  padding: 20px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);

  .font {
    margin-top: 12px;
  }

  .dragbox {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 0px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;

    span {
      color: #1890FF;
    }


  }
}

.dragimgbox:hover {

  .dragbox {
    background: $active-alpha;
    border-color: $active-theme ;
  }

  // color: $active-theme ;
}


.upload-demo {
  width: 236px;
  margin: 0 auto;
}

.imgdragbox {
  // padding: 15px;
  position: relative;

  .fileImg {
    max-width: 236px;
    max-height: 236px;
  }

  .delbox {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
  }
}

.imgdragbox:hover {
  .delbox {
    display: flex;
  }
}</style>